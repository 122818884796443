import Unit from './index';

export default class ProductUnit extends Unit {

    listProducts = (body, cbSuccess, cbError) => {
        this.post(`/products/list`, body, cbSuccess, cbError);
    }

    getProduct = (productId, cbSuccess, cbError) => {
        this.get(`/products/${productId}`, cbSuccess, cbError);
    }

    createProduct = (body, cbSuccess, cbError) => {
        this.post('/products', body, cbSuccess, cbError);
    }

    updateProduct = (body, cbSuccess, cbError) => {
        this.put('/products', body, cbSuccess, cbError);
    }

    deleteProduct = (productId, cbSuccess, cbError) => {
        this.delete(`/products`, { id: productId }, cbSuccess, cbError);
    }
}
