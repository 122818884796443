import React, { useState } from 'react';

import wrlog from '../../helper/functions/wrlog';
import CardPayment from './stripe/card';
import PaypalPayment from './paypal';
import SepaPayment from './stripe/sepa';
import { useCookies } from 'react-cookie';

import {
    useStripe,
    useElements,
    CardElement,
    IbanElement
} from "@stripe/react-stripe-js";
import OrderUnit from '../../communicator/http/OrderUnit';
import { useNavigate } from 'react-router-dom';



const PaymentMethods = ({ setLoading }) => {
    const navigate = useNavigate();

    const [cookies, setCookies] = useCookies(['afreshedCart']);

    const stripe = useStripe();
    const elements = useElements();

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [isValid, setIsValid] = useState(false);

    const orderUnit = new OrderUnit();

    const getPaymentDetails = () => {

        setLoading(true);

        let paymentProvider = 'stripe';

        if (selectedPaymentMethod === 'paypal') {
            paymentProvider = 'paypal';
        }

        let items = [];

        cookies.afreshedCart.items.forEach(item => {
            items.push({
                product_id: item.product_id,
                quantity: item.quantity,
            })
        });

        let body = {
            customer: {
                email: cookies.afreshedCart.customer.email,
                first_name: cookies.afreshedCart.customer.firstName,
                last_name: cookies.afreshedCart.customer.lastName,
                address_1: cookies.afreshedCart.customer.address1,
                street_number: cookies.afreshedCart.customer.streetNumber,
                address_2: cookies.afreshedCart.customer.address2,
                postcode: cookies.afreshedCart.customer.postcode,
                city: cookies.afreshedCart.customer.city,
                legal_text: cookies.afreshedCart.customer.legalText,
                receive_news: cookies.afreshedCart.customer.receiveNews,
                phone: cookies.afreshedCart.customer.phone || '',
            },
            items: items,
            payment: {
                provider: paymentProvider
            }
        }

        const cbSuccess = (res) => {
            wrlog("RESULT", res);

            if (paymentProvider === 'paypal') {
                if (res.returnUrl !== undefined) {
                    window.location.replace(res.returnUrl);
                }
            } else if (paymentProvider === 'stripe') {
                handleStripePayment(res);
            }
        }

        const cbError = (err) => {
            wrlog("ERROR", err);
        }

        orderUnit.addOrder(body, cbSuccess, cbError);
    }

    const handleStripePayment = (response) => {
        if (!stripe || !elements) {
            return;
        }

        if (selectedPaymentMethod === 'card') {
            stripe.confirmCardSetup(response.clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
                return_url: response.returnUrl,
            })
                .then(function (result) {
                    wrlog("STRIPE RESULT", result)
                    if (result.error) {
                        setLoading(false);
                    }
                    if (result.setupIntent?.status === "succeeded") {
                        navigate(`/waiting/${response.id}`);
                    }
                });
        } else if (selectedPaymentMethod === 'sepa') {
            stripe.confirmSepaDebitSetup(response.clientSecret, {
                payment_method: {
                    card: elements.getElement(IbanElement),
                },
                return_url: response.returnUrl,
            })
                .then(function (result) {
                    wrlog("STRIPE RESULT", result)
                    if (result.error) {
                        setLoading(false);
                    }
                    if (result.setupIntent?.status === "succeeded") {
                        navigate(`/waiting/${response.id}`);
                    }
                });

        }



    }

    return <div style={{ marginTop: 50, position: 'relative' }}>

        <CardPayment
            show={selectedPaymentMethod === 'card' || selectedPaymentMethod === null}
            setOpen={(val) => setSelectedPaymentMethod(val)}
            open={selectedPaymentMethod === 'card'}
            setValid={setIsValid}
        />
        <SepaPayment
            show={selectedPaymentMethod === 'sepa' || selectedPaymentMethod === null}
            setOpen={setSelectedPaymentMethod}
            open={selectedPaymentMethod === 'sepa'}
            setValid={setIsValid}
        />
        <PaypalPayment
            show={selectedPaymentMethod === 'paypal' || selectedPaymentMethod === null}
            setOpen={setSelectedPaymentMethod}
            open={selectedPaymentMethod === 'paypal'}
            setValid={setIsValid}
        />

        <div
            onClick={getPaymentDetails}
            className={`button continueButton ${!isValid ? 'disabled' : undefined}`}>Kostenpflichtig bestellen</div>
    </div>

}

export default PaymentMethods;