import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";
import CartUnit from '../../communicator/http/CartUnit';
import { useCookies } from 'react-cookie';
import Navigation from '../../elements/navigation';
import Footer from '../../elements/footer';
import Message from '../../elements/message';
import OrderUnit from '../../communicator/http/OrderUnit';
import wrlog from '../../helper/functions/wrlog';

const Success = () => {

    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const { handleSubmit, control, formState: { errors } } = useForm();

    const [cookie, setCookie, removeCookie] = useCookies(['afreshedCart']);

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {

        if (location.state !== null && location.state.orderId !== undefined && location.state.token !== undefined) {
            setShowForm(true);
        }


        window.scrollTo({
            top: 0
        })

        removeCookie('afreshedCart');

    }, []);


    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {
        const orderUnit = new OrderUnit();

        setLoading(true);

        let body = {
            id: location.state.orderId,
            token: location.state.token,
            note: data.note,
        };

        // Success function for Update User
        const cbSuccess = (res) => {
            setLoading(false);
            setErrorMessageTitle("Abstellort gespeichert");
            setErrorMessageBody('Deine Retterbox wird an an dem von dir angegebenen Ort abgestellt. Du kannst den Abstellort jederzeit in deinem Konto ändern.');
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
        }
        const cbError = (err) => {
            wrlog(err.response)
            wrlog(err.response.data)
            wrlog(err.response.data.exception)
            wrlog(err.response.data.exception.message)
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle('Fehler');
            setErrorMessageBody(err.response.data.exception.message);
        }

        orderUnit.setNote(body, cbSuccess, cbError);
    }


    return <>
        <Container style={{ paddingTop: 50, paddingBottom: 50, minHeight: "calc(100vh - 207px)" }}>
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{}}>
                    <Navigation currentStep={2} />
                    <h2 className='normal' style={{ marginBottom: 10 }}>Geschafft!</h2>

                    <h5 className='normal'>Deine Bestellung ist bei uns eingegangen, <br /> Wir freuen uns dich in der Rettercommunity <br /> Willkomen heissen zu dürfen!</h5>

                    <br />
                    <br />
                    <p>
                        Wir haben dir eine E-Mail geschickt mit einem Passwort, mit dem du dich deinem Kundenkonto anmelden kannst. Dort kannst du jederzeit deine Bestellung pausieren.
                    </p>
                </Col>
            </Row>
            {showForm &&
                <Row style={{ marginRight: 0 }}>
                    <Col md={6} style={{}}>
                        <h3 className='normal' style={{ marginTop: 40 }}>Möchtest du uns sagen, wo wir die Kiste abstellen dürfen, wenn du nicht zu Hause bist? (Optional)</h3>
                        <form onSubmit={handleSubmit(doSubmit)}>
                            <FormController
                                name={"note"}
                                rules={{
                                    required: false,
                                }}
                                control={control}
                                render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        multiline
                                        className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                        error={errors.note}
                                        id="standard-basic"
                                        label={`Abstellort`}
                                    />
                                )}
                            />
                            <input type={'submit'} className='button' value="Abschicken" />

                        </form>
                    </Col>
                </Row>
            }
        </Container>
        <Footer />
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />
        <Loading visible={loading} />
    </>

}

export default Success;