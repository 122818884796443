import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './style.module.scss';
import Logo from '../../assets/logoBig.png';

import SupportLogo from '../../assets/bx-support.png';
import Zertifikat from '../../assets/Zertifikatshinweis.png';
import CartUnit from '../../communicator/http/CartUnit';
import wrlog from '../../helper/functions/wrlog';

const TopBar = () => {

    const location = useLocation();
    const [cookies, setCookies] = useCookies(['afreshedCart']);
    const navigate = useNavigate();

    const getCartIdParam = () => {
        let search = new URLSearchParams(location.search);
        return search.get('c');
    }

    useEffect(() => {

        const cartId = getCartIdParam();

        if (cartId === null) {
            return;
        }

        const cartUnit = new CartUnit();
        cartUnit.getCart(cartId, (res) => {
            wrlog(res);
            setCookies('afreshedCart', res);
            navigate('/payment');
        }, () => { });

    }, []);

    return <>
        <div
            className={`${styles.container}`}
        >
            <div
                className={`${styles.inner}`}
            >
                <img className={styles.logo} alt="Afreshed Logo" src={Logo} />
                <div className={styles.navigation}>
                    <img src={SupportLogo} alt="Support Logo" />
                    <a href="mailto:info@afreshed.at">info@afreshed.at</a>
                    {" | "}
                    <a href="tel:+436601111323">0660 1111 323</a>
                </div>
                <img className={styles.certificate} src={Zertifikat} alt="Zertifikat Logo" />
            </div>
        </div>
    </>

}

export default TopBar;