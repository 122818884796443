import React from 'react';

import styles from './style.module.scss';

const Navigation = ({currentStep}) => {

    return <>
        <div
            className={`${styles.container}`}
        >
            <div className={`${styles.item} ${currentStep >= 0 ? styles.active : undefined}`}>
                <div className={`${styles.circle}`}>1</div>
                <span className={`${currentStep === 0 ? styles.active : undefined}`}>Versand</span>
            </div>
            <div className={`${styles.item} ${currentStep >= 1 ? styles.active : undefined}`}>
                <div className={`${styles.circle}`}>2</div>
                <span className={`${currentStep === 1 ? styles.active : undefined}`}>Zahlung</span>
            </div>
            <div className={`${styles.item} ${currentStep >= 2 ? styles.active : undefined}`}>
                <div className={`${styles.circle}`}>3</div>
                <span className={`${currentStep === 2 ? styles.active : undefined}`}>Fertig</span>
            </div>
        </div>
    </>

}

export default Navigation;