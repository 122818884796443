import React, { useState, useEffect } from 'react';
import { formatMoney } from '../../helper/functions/formatPrice';
import styles from './style.module.scss';
import { useCookies } from 'react-cookie';

import Kalender from "../../assets/calendar-check.png";
import Geld from "../../assets/money.png";
import Baum from "../../assets/tree-line.png";
import Mc from "../../assets/mastercardG.png";
import Visa from "../../assets/visaG.png";
import PayPal from "../../assets/cc-paypalG.png";
import Sepa from "../../assets/SEPAG.png";
import Gp from "../../assets/googlepayG.png";
import Ap from "../../assets/apple-payG.png";
import Ccdc from "../../assets/cc-diners-clubG.png";
import Ae from "../../assets/american-expressG.png";
import CartUnit from '../../communicator/http/CartUnit';



const CartSidebar = ({ mobile, scrollToCart, initRef }) => {

    const [cookies, setCookies] = useCookies(['afreshedCart']);

    const [totals, setTotals] = useState({ total: 0, tax: 0 });

    useEffect(() => {

        const cartUnit = new CartUnit();
        let body = { items: [] };

        cookies.afreshedCart.items.forEach(item => {
            body.items.push({
                product_id: item.product_id,
                quantity: item.quantity,
            })
        });

        cartUnit.checkCart(body, (res) => {
            setTotals({ total: res.total, tax: res.tax });
        })

    }, []);


    const renderCart = () => {

        if (cookies.afreshedCart.items === undefined) {
            return <></>;
        }

        return cookies.afreshedCart.items.map(cartItem => {
            return <div className={styles.cartItemContainer}>
                <div className={styles.cartItemImage}>
                    <img src={cartItem.image} alt="Produktbild" />
                </div>
                <div className={styles.cartItemContent}>
                    <h3 className={styles.productTitle}>{cartItem.quantity}x {cartItem.title}</h3>
                    <p>Nächste Auslieferung am {cartItem.nextDeliveryDate}</p>
                    <div className={styles.priceContainer}>{formatMoney(cartItem.price)}</div>
                </div>
            </div>
        })
    }

    return <div className={`${styles.container} ${mobile !== undefined ? styles.isMobile : undefined}`} ref={initRef}>
        <div className={styles.inner}>
            {mobile === undefined && <h4>Bestellinformationen</h4>}
            {mobile === undefined && renderCart()}
            {mobile === undefined && <>
                <hr />
                <table className={styles.cartTable}>
                    <tr>
                        <td>Zwischensumme</td>
                        <td>{formatMoney(totals.total)}</td>
                    </tr>
                    <tr>
                        <td>Versand</td>
                        <td>gratis</td>
                    </tr>
                </table>
                <hr />
            </>
            }

            {mobile === undefined ? <table className={styles.cartTable}>
                <tr>
                    <td><h4>Gesamtsumme</h4></td>
                    <td><div className={styles.priceContainer}>{formatMoney(totals.total)}</div><small>inkl. {formatMoney(totals.tax)} USt.</small></td>
                </tr>
            </table> :
                <table className={styles.cartTable}>
                    <tr onClick={scrollToCart}>
                        <td>{cookies.afreshedCart.items.length} Produkt{cookies.afreshedCart.items.length !== 1 && "e"} im Warenkorb</td>
                        <td>anzeigen</td>
                    </tr>
                    <tr>
                        <td><h5>Gesamtsumme</h5>
                        </td>
                        <td><div className={styles.priceContainer}>{formatMoney(totals.total)}</div><small>inkl. {formatMoney(totals.tax)} USt.</small></td>
                    </tr>
                </table>
            }

            
            {mobile === undefined && <>
                <br />
                <h4>Informationen zum Abo</h4>
                <table className={styles.iconTable}>
                    <tr>
                        <td><img src={Geld} alt="Geld" style={{ width: "25px" }} /></td>
                        <td>bei Bestellung keine Zahlung erforderlich: Geld wird erst vor der Lieferung abgebucht</td>
                    </tr>
                    <tr>
                        <td><img src={Kalender} alt="Kalender" style={{ width: "25px" }} /></td>
                        <td>Jederzeit pausierbar &amp; kündbar</td>
                    </tr>
                    <tr>
                        <td><img src={Baum} alt="Baum" style={{ width: "25px" }} /></td>
                        <td>Ein gepflanzter Baum pro Lieferung</td>
                    </tr>
                </table>
                <div className={styles.iconRow}>
                    <img src={Mc} alt="mc" />
                    <img src={Visa} alt="Visa" />
                    <img src={PayPal} alt="Paypal" />
                    <img src={Sepa} alt="Sepa" />
                    <img src={Gp} alt="Gp" />
                    <img src={Ap} alt="Ap" />
                    <img src={Ccdc} alt="Ccdc" />
                    <img style={{ width: "30px" }} src={Ae} alt="Ae" />
                </div>
            </>}
        </div>
    </div>
}

export default CartSidebar;