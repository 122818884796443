import React from 'react';

import { CardElement } from '@stripe/react-stripe-js';
import Accordion from '../../accordion';
import Kreditkarte from "../../../assets/Credit_card.png";

import styles from './style.module.scss';

const CardPayment = ({ open, setOpen, show, setValid }) => {

    const CARD_ELEMENT_OPTIONS = {
        hidePostalCode: true,
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Source Sans Pro',
                fontSmoothing: "antialiased",
                fontSize: "18px",
                "::placeholder": {
                    color: "#aab7c4",
                },
                ":-webkit-autofill": {
                    color: "#32325d",
                },
                width: "100%",
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };


    const images = <img alt="Kredikarten Logos" src={Kreditkarte} />;

    return <Accordion
        open={open}
        show={show}
        setOpen={setOpen}
        id={"card"}
        title={"Kreditkarte"}
        rightContent={images}
    >
        <div className={`content ${styles.elementContainer}`}>
            <CardElement onChange={(el) => setValid(el.complete)} onReady={() => setValid(false)} className={styles.cardElement} options={CARD_ELEMENT_OPTIONS} />
        </div>
    </Accordion>

}

export default CardPayment;