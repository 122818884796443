import Unit from './index';

export default class OrderUnit extends Unit {

    addOrder = (body, cbSuccess, cbError) => {
        this.post('/order', body, cbSuccess, cbError);
    }

    check = (orderId, cbSuccess, cbError) => {
        this.get('/order/' + orderId + '/status', cbSuccess, cbError);
    }

    activatePaypalBillingAgreement = (orderId, baToken, cbSuccess, cbError) => {
        this.post('/order/activateBillingAgreement', { order_id: orderId, ba_token: baToken }, cbSuccess, cbError);
    }

    setNote = (body, cbSuccess, cbError) => {
        this.put('/order', body, cbSuccess, cbError);
    }

    paypalBillingAgreementManualRequest = (paypalBillingAgreementManualRequestToken, cbSuccess, cbError) => {
        this.get('/order/paypalBillingAgreementManualRequest/' + paypalBillingAgreementManualRequestToken, cbSuccess, cbError);
    }

    activatePaypalBillingAgreementRequest = (paypalBillingAgreementManualRequestToken, baToken, cbSuccess, cbError) => {
        this.post('/order/activateBillingAgreement/request', { paypal_billing_agreement_manual_request_token: paypalBillingAgreementManualRequestToken, ba_token: baToken }, cbSuccess, cbError);
    }

}
