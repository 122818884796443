import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import ReactHtmlParser from 'react-html-parser';

const Message = ({ title, open, body, type, buttonText, buttonStyle, buttonAction, small, buttonTwoText, buttonTwoAction, buttonTwoStyle }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (open) {
            setIsOpen(true);
            setShow(true);
        } else {
            setShow(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 200);
        }
    }, [open]);

    if (!isOpen) {
        return null;
    }

    const container = () => (
        <div className={`${styles.container} ${small === true ? styles.small : undefined}`}>
            <h3 className={`${styles[type]}`}>{title}</h3>
            {body !== undefined && body !== '' && <div className={styles.text}>{ReactHtmlParser(body)}</div>}
            <div
                className={`button ${styles.button} ${styles[type]}`}
                onClick={buttonAction}
                style={{...buttonStyle}}
                >
                {ReactHtmlParser(buttonText)}
            </div>

            {buttonTwoText !== undefined && buttonTwoAction !== undefined &&
                <div
                    className={`${styles.button} ${styles.small_button} ${styles[type]}`}
                    style={{marginTop: 20, ...buttonTwoStyle}}
                    onClick={buttonTwoAction}
                >
                    {ReactHtmlParser(buttonTwoText)}
                </div>}
        </div>
    );

    if (small === true) {
        return container();
    }

    return <><div className={`${styles.overlay} ${show ? styles.show : undefined}`}>
        {container()}
    </div>
    </>

}

export default Message;