import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import Footer from '../../elements/footer';
import TopBar from '../../elements/topBar';

const EmptyCart = () => {

    const [cookies, setCookies] = useCookies(['afreshedCart']);

    useEffect(() => {

        window.scrollTo({
            top: 0
        })

    }, []);


    const addToCart = () => {

        const cartJson = {
            items: [
                {
                    image: 'https://secure.afreshed.at/static/media/3.07789377.png',
                    title: 'Bio Retterbox Gross',
                    delivery: 'Alle 2 Wochen',
                    nextDeliveryDate: '24. Jänner',
                    price: 19.99,
                    quantity: 1,
                    product_id: "8ee636cd6d91c6110374fa9d14d54c4db04a"
                }
            ]
        }

        setCookies('afreshedCart', cartJson);

    }



    return <>
        <Container style={{  }} className="alignedCenter">
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{ paddingTop: 50, paddingBottom: 50, minHeight: "calc(100vh - 207px)" }}>
                    <div>
                        <h2 className='normal' style={{ marginBottom: 10 }}>Warenkorb ist leer!</h2>

                        <h5 className='normal'>Es befindet sich keine Retterbox im Warenkorb.</h5>

                        <br />
                        <br />

                        <div className="button" onClick={addToCart}>Hinzufügen</div>

                        <br />
                        <br />

                        <a href='https://afreshed.at/' className="button">Zurück zu den Produkten</a>
                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>

}

export default EmptyCart;