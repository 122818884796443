import React, { useEffect, useState } from 'react';

import styles from './style.module.scss';
import Message from '../message';
import Loading from '../../helper/screens/Loading';
import OrderUnit from '../../communicator/http/OrderUnit';
import { useCookies } from 'react-cookie';
import wrlog from '../../helper/functions/wrlog';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const QuickPay = () => {

    const stripe = useStripe();

    const [openApplePayPopup, setOpenApplePayPopup] = useState(false);
    const [cookies, setCookies] = useCookies(['afreshedCart']);
    const [paymentRequest, setPaymentRequest] = useState(false);
    const [loading, setLoading] = useState(false);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");
    const [errorMessageSmall, setErrorMessageSmall] = useState(true);

    const [stripeInstantPaymentType, setStripeInstantPaymentType] = useState(false);

    const [ready, setReady] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        let items = [];
        let paymentRequestItems = [];

        cookies.afreshedCart.items.forEach(item => {
            paymentRequestItems.push({
                label: item.title,
                amount: Math.round(item.price * 100),
            })
        });


        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'AT',
                currency: 'eur',
                displayItems: paymentRequestItems,
                total: {
                    label: 'JETZT 0€, es wird erst vor Lieferung abgebucht.',
                    amount: 0,
                },
                requestPayerName: true,
                requestPayerEmail: true,
                requestPayerPhone: true,
                requestShipping: true,
                shippingOptions: [
                    {
                        id: 'free-shipping',
                        label: 'Gratis - Versand',
                        detail: 'Kostenloser Versand durch unsere Versandpartner',
                        amount: 0,
                    }
                ]
            });

            pr.canMakePayment().then(result => {
                setReady(true);
                wrlog("result", result)
                if (result) {
                    if (result.applePay !== false) {
                        setStripeInstantPaymentType('applePay');
                    }
                }
            })

            setPaymentRequest(pr);
        }

    }, [stripe])

    const initApplePay = () => {
        const orderUnit = new OrderUnit();

        console.log("paymentRequest", paymentRequest);

        setOpenApplePayPopup(true);

        paymentRequest.on('paymentmethod', async (ev) => {
            console.log("PAYMENT REQUEST", ev);

            if (ev.shippingAddress.country !== 'AT') {
                ev.complete('fail');

                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle('Nicht in Österreich?');
                setErrorMessageBody("Es sieht so aus, als würden Sie nicht in Österreich wohnen. Derzeit liefern wir ausschließlich innerhalb Österreichs.");

                return false;
            }

            let items = [];
            cookies.afreshedCart.items.forEach(item => {
                items.push({
                    product_id: item.product_id,
                    quantity: item.quantity,
                })
            });

            let body = {
                customer: {
                    email: ev.payerEmail,
                    name: ev.payerName,
                    address: ev.shippingAddress.addressLine[0],
                    address_2: ev.shippingAddress.addressLine[1] !== undefined ? ev.shippingAddress.addressLine[1] : "",
                    postcode: ev.shippingAddress.postalCode,
                    city: ev.shippingAddress.city,
                    legal_text: true
                },
                items: items,
                payment: {
                    provider: 'stripe',
                    methodId: ev.paymentMethod.id
                }
            }

            const cbSuccess = (response) => {
                ev.complete('success');
                navigate(`/waiting/${response.id}`);
            }

            const cbError = (err) => {
                ev.complete('fail');
                setErrorMessageButtonText("OK");
                setErrorMessageType("error");
                setErrorMessageOpen(true);
                setErrorMessageSmall(false);
                setErrorMessageTitle(err.response.data.exception.title);
                setErrorMessageBody(err.response.data.exception.message);
            }

            orderUnit.addOrder(body, cbSuccess, cbError);

        })

    }

    const initPayPalPayment = () => {
        const orderUnit = new OrderUnit();

        setLoading(true);

        let items = [];
        cookies.afreshedCart.items.forEach(item => {
            items.push({
                product_id: item.product_id,
                quantity: item.quantity,
            })
        });

        let body = {
            items: items,
            payment: {
                provider: 'paypal',
                quick: true
            }
        }

        const cbSuccess = (response) => {
            if (response.returnUrl !== undefined) {
                window.location.replace(response.returnUrl);
            }
        }

        const cbError = (err) => {
            setLoading(false);
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
            setErrorMessageSmall(false);
            setErrorMessageTitle(err.response.data.exception.title);
            setErrorMessageBody(err.response.data.exception.message);
        }

        orderUnit.addOrder(body, cbSuccess, cbError);

    }

    return <>
        <div
            className={`${styles.container}`}
        >
            <div className={styles.headline}>Entweder Express Checkout</div>
            {ready ? <>
                {stripeInstantPaymentType === 'applePay' &&
                    <div className={`${styles.button} ${styles.apple}`} onClick={() => {
                        initApplePay();
                    }}></div>
                }
                <div
                    className={`${styles.button} ${styles.paypal}`}
                    onClick={initPayPalPayment}></div>
            </> : <Loading containerStyle={{ height: 60 }} visible={true} relative />}
        </div>
        <Message
            open={openApplePayPopup}
            close={() => setOpenApplePayPopup(false)}
            type="info"
            buttonText="OK"
            title="Apple Pay"
            body="Wir belasten dein Apple Pay-Konto erst bei der ersten Lieferung. Du wirst jetzt aufgefordert eine Zahlung über 0 Euro zu tätigen. Bitte gib die Zahlung frei, damit du in Zukunft keine Weiteren Schritte tätigen must um unsere Retterbox zu bekommen. Du bestätigst damit unsere AGB &amp; Datenschutzbestimmungen."
            buttonAction={() => {
                setOpenApplePayPopup(false);
                paymentRequest.show();
            }}
            buttonTwoText="Abbrechen"
            buttonTwoAction={() => setOpenApplePayPopup(false)}
        >
            <h4>Apple Pay</h4>
        </Message>
        <Message
            open={errorMessageOpen}
            type={errorMessageType}
            small={errorMessageSmall}
            title={errorMessageTitle}
            body={errorMessageBody}
            buttonText={errorMessageButtonText}
            buttonAction={() => setErrorMessageOpen(false)}
        />
        <Loading
            visible={loading}
        />
    </>

}

export default QuickPay;