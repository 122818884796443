import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";
import OrderUnit from '../../communicator/http/OrderUnit';
import { useCookies } from 'react-cookie';
import Navigation from '../../elements/navigation';
import Footer from '../../elements/footer';
import wrlog from '../../helper/functions/wrlog';

const PaypalBillingAgreement = () => {

    const params = useParams();
    const location = useLocation();

    const orderId = params.id;
    const paypalBillingAgreementManualRequestToken = params.paypalBillingAgreementManualRequestToken;

    const navigate = useNavigate();

    const orderUnit = new OrderUnit();

    const getPaypalBillingAgreementToken = () => {
        let search = new URLSearchParams(location.search);
        return search.get('ba_token');
    }

    useEffect(() => {

        const baToken = getPaypalBillingAgreementToken();

        if(baToken === null){
            navigate('/error', { replace: true });
        }

        const cbSuccess = (res) => {

            if(orderId !== undefined){
                navigate(`/waiting/${orderId}`, { replace: true });
            }else if(paypalBillingAgreementManualRequestToken !== null){
                navigate(`/success/pbar`, { replace: true });
            }

        }

        const cbError = () => {
            navigate('/error', { replace: true });
        }

        if (baToken !== null) {
            if(orderId !== undefined){
                orderUnit.activatePaypalBillingAgreement(orderId, baToken, cbSuccess, cbError);
            }else if(paypalBillingAgreementManualRequestToken !== null){
                orderUnit.activatePaypalBillingAgreementRequest(paypalBillingAgreementManualRequestToken, baToken, cbSuccess, cbError);
            }
        }

    }, []);

    return <>
        <Container style={{ padding: 0 }} className="alignedCenter">
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{ paddingTop: 50, justifyContent: 'center', minHeight: "calc(100vh - 207px)", paddingBottom: 50 }}>
                    <div>
                        <Loading relative containerStyle={{ height: 100 }} visible={true} />
                        <h2 className='normal' style={{ marginBottom: 10, textAlign: 'center' }}>Deine Bestellung wird bearbeitet.</h2>
                        <h5 className='normal' style={{ textAlign: 'center' }}>Bitte warte einen Moment, wir bearbeiten deine Bestellung, dies kann einige Sekunden dauern.</h5>
                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>

}

export default PaypalBillingAgreement;