import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import { BASENAME } from './spectory-config';
import CustomerData from './screens/customerData';
import { changeLanguageHandler } from './helper/multilingual';
import { useCookies } from 'react-cookie';
import Payment from './screens/payment';
import TopBar from './elements/topBar';
import Success from './screens/success';
import PaypalBillingAgreement from './screens/paypalBillingAgreement';
import Waiting from './screens/waiting';
import Error from './screens/error';
import AddToCart from './screens/addToCartLink';
import PaypalBillingAgreementManualRequest from './screens/paypalBillingAgreementManualRequest';
import PaypalBillingAgreementManualRequestSuccess from './screens/paypalBillingAgreementManualRequest/success';

const App = () => {
    const [cookies, setCookies] = useCookies(['afreshedCart']);
    const [emptyCart, setEmptyCart] = useState(null);

    useEffect(() => {

        // const cartJson = {
        //     items: [
        //         {
        //             image: 'https://secure.afreshed.at/static/media/3.07789377.png',
        //             title: 'Bio Retterbox Gross',
        //             delivery: 'Alle 2 Wochen',
        //             nextDeliveryDate: '24. Jänner',
        //             price: 19.99,
        //             quantity: 1,
        //             product_id: "8ee636cd6d91c6110374fa9d14d54c4db04a"
        //         }
        //     ]
        // }

        // setCookies('afreshedCart', cartJson);

    }, []);

    useEffect(() => {
        changeLanguageHandler();

        if (cookies.afreshedCart === undefined || cookies.afreshedCart.items === undefined) {
            setEmptyCart(true);
        } else {
            setEmptyCart(false);
        }

    }, [cookies]);

    if (emptyCart === null) {
        return <></>;
    }

    return (
        <Router basename={BASENAME}>
            <TopBar />
            <Routes>
                <Route path='/' element={<CustomerData emptyCart={emptyCart} />} />
                <Route path='/addtocart/:id' element={<AddToCart />} />
                <Route path='/payment' element={<Payment emptyCart={emptyCart} />} />
                <Route path='/pbar/:id' element={<PaypalBillingAgreementManualRequest />} />
                <Route path='/ba/:id' element={<PaypalBillingAgreement />} />
                <Route path='/ba/manually/:paypalBillingAgreementManualRequestToken' element={<PaypalBillingAgreement />} />
                <Route path='/waiting/:id' element={<Waiting />} />
                <Route path='/success' element={<Success />} />
                <Route path='/success/pbar' element={<PaypalBillingAgreementManualRequestSuccess />} />
                <Route path='/error' element={<Error />} />
                <Route path='*' element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;