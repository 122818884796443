/**
 * Formats the date from the server
 * @param object dateTime
 * 
 * @return string din-formatted date
 */
export const formatDbDate = (dateTime) => {

    try {
        let dateObject = new Date();

        if (typeof dateTime === 'string') {
            dateObject = new Date(dateTime);
        } else {
            let dateTimeParts = dateTime.date.split(/[- :]/);
            dateTimeParts[1]--;

            dateObject = new Date(...dateTimeParts);
        }

        let day = dateObject.getDate();
        if (day < 10) day = '0' + day;

        let month = dateObject.getMonth() + 1;
        if (month < 10) month = '0' + month;

        return `${day}.${month}.${dateObject.getFullYear()}`;
    } catch {
        return '';
    }
}