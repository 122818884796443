import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";
import OrderUnit from '../../communicator/http/OrderUnit';
import { useCookies } from 'react-cookie';
import Navigation from '../../elements/navigation';
import Footer from '../../elements/footer';

const Waiting = () => {

    const params = useParams();

    const orderId = params.id;

    const navigate = useNavigate();

    const orderUnit = new OrderUnit();


    useEffect(() => {
        const intervalID = setInterval(load, 2000);

        return () => {
            clearInterval(intervalID);
        }
    }, []);

    const load = () => {

        const cbSuccess = (res) => {

            if (res.status !== undefined) {
                if (res.status === "completed") {
                    navigate('/success', { replace: true, state: {orderId: orderId, token: res.token} });
                } else if (res.status === "failed" || res.status === "canceled" || res.status === "action_required") {
                    navigate('/error', { replace: true, state: { type: res.status, ...res } });
                }
            }

        }
        const cbError = () => {
            navigate('/error', { replace: true, state: { type: "undefined" } });
        }

        orderUnit.check(orderId, cbSuccess, cbError);
    }

    


    return <>
        <Container style={{ padding: 0 }} className="alignedCenter">
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{ paddingTop: 50, justifyContent: 'center', minHeight: "calc(100vh - 207px)", paddingBottom: 50 }}>
                    <div>
                        <Loading relative containerStyle={{ height: 100 }} visible={true} />
                        <h2 className='normal' style={{ marginBottom: 10, textAlign: 'center' }}>Deine Bestellung wird bearbeitet.</h2>
                        <h5 className='normal' style={{ textAlign: 'center' }}>Bitte warte einen Moment, wir bearbeiten deine Bestellung, dies kann einige Sekunden dauern.</h5>

                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>

}

export default Waiting;