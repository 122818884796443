import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Navigation from '../../elements/navigation';
import Footer from '../../elements/footer';

const PaypalBillingAgreementManualRequestSuccess = () => {

    return <>
        <Container style={{ paddingTop: 50, paddingBottom: 50, minHeight: "calc(100vh - 207px)" }}>
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{}}>
                    <h2 className='normal' style={{ marginBottom: 10 }}>Vielen Dank!</h2>

                    <h5 className='normal'>Wir haben deine Zahlungsdaten sicher gespeichert und werden dir deine neue Retterbox zuschicken.</h5>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>

}

export default PaypalBillingAgreementManualRequestSuccess;