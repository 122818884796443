import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
    return <Container fluid style={{ background: '#f6f6f6', padding: 40, textAlign: 'center' }}>
        <Row>
            <Col>
                ©2022 afreshed GmbH | All rights reserved | <a href="https://afreshed.at/agb/">Widerrufsbelehrung</a> | <a href="https://afreshed.at/datenschutzerklaerung/">Datenschutzerklärung</a>
            </Col>
        </Row>
    </Container>
}

export default Footer;