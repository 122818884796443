// import UserUnit from '../../communicator/http/User';

// export const isLoggedIn = () => {

//     const userUnit = new UserUnit();

//     return new Promise((resolve, reject) => {

//         userUnit.validateToken((res) => resolve(res), (err) => reject(err))

//     });

// }

export const getUserToken = () => localStorage.getItem('token');



// Check if user has certain permission
export const hasPermission = (permission_type, action) => {

    let permissions = sessionStorage.getItem('permissions');

    if (permissions == null) return false;

    permissions = JSON.parse(permissions);

    if (permissions[permission_type] === undefined) return false;

    if (typeof action === 'object') {
        let valid = false;

        action.forEach(_action => {
            if (permissions[permission_type][_action] === true) {
                valid = true;
            }
        });

        return valid;
    }

    return permissions[permission_type][action] === true;

}