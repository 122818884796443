import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import CartSidebar from '../../elements/cartSidebar';
import { useCookies } from 'react-cookie';

import Navigation from '../../elements/navigation';
import PaymentMethods from '../../elements/paymentMethods';
import Footer from '../../elements/footer';

const Payment = ({ emptyCart }) => {

    const [loading, setLoading] = useState(false);

    // const [errorMessageOpen, setErrorMessageOpen] = useState(true);
    // const [errorMessageType, setErrorMessageType] = useState('info');
    // const [errorMessageTitle, setErrorMessageTitle] = useState("Wichtiger Hinweis");
    // const [errorMessageBody, setErrorMessageBody] = useState("Es kann sein, dass Ihre Änderungen keine Auswirkungen auf Ihre nächste Lieferung haben, falls Ihre Daten bereits an unsere Lieferanten weitergegeben wurden.");
    // const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK, verstanden");


    const [cookie, setCookie] = useCookies(['afreshedCart']);

    const navigate = useNavigate();

    useEffect(() => {

        window.scrollTo({
            top: 0
        })

        if (cookie.afreshedCart === undefined || cookie.afreshedCart.customer === undefined) {
            navigate("/");
        }

    }, []);

    if (emptyCart) {
        return null;
    }

    return <>
        <Container fluid style={{ padding: 0 }}>
            <Row style={{ marginRight: 0 }}>
                <Col md={7} style={{ paddingTop: 50 }} className={"leftContainerMaxWidthWrapper"}>
                    <Container className={"leftContainerMaxWidth"}>
                        <Row>
                            <Col md={12}>
                                <Navigation currentStep={1} />
                                <h2 className='normal' style={{ marginBottom: 10 }}>Bezahlmethode</h2>
                                <h5 className='normal'>Alle Transaktionen sind sicher und verschlüsselt</h5>

                                <PaymentMethods
                                    setLoading={setLoading}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={5} style={{ paddingRight: 0, marginBottom: 0 }}>
                    <CartSidebar />
                </Col>
            </Row>
        </Container>
        <Footer />
        <Loading visible={loading} />
    </>

}

export default Payment;