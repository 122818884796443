import React, { useState, useEffect } from 'react';
import Loading from '../../helper/screens/Loading.js';
import { useCookies } from 'react-cookie';

import ProductsUnit from '../../communicator/http/Products';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDbDate } from '../../helper/functions/formatDate.js';

const AddToCart = () => {

    const [cookies, setCookies] = useCookies(['afreshedCart']);

    const params = useParams();
    const productId = params.id;

    const navigate = useNavigate();

    useEffect(() => {

        const productsUnit = new ProductsUnit();

        productsUnit.getProduct(productId, (productData) => {
            console.log("res", productData);

            let cart = [];
            if (cookies.afreshedCart !== undefined) {
                cart = cookies.afreshedCart;
            }

            let price = productData.price;
            if (price > productData.reducedPrice) {
                price = productData.reducedPrice;
            }

            cart = {
                items: [
                    {
                        image: productData.imageUrl,
                        title: productData.title,
                        delivery: productData.intervalOfDelivery,
                        nextDeliveryDate: formatDbDate(productData.nextDeliveryDate) + ' - ' + formatDbDate(productData.nextDeliveryDateEnd),
                        price: price,
                        regularPrice: productData.price,
                        quantity: 1,
                        product_id: productData.id
                    }
                ]
            };

            setCookies('afreshedCart', cart, {
                path: '/',
            });

            // navigate('/', { replace: true })

        });

    }, []);

    /**
    
     */

    return <Loading visible={true} />
}

export default AddToCart;