import Unit from './index';

export default class CustomerUnit extends Unit {

    getCustomer = (cbSuccess, cbError) => {
        this.get(`/customers/me`, cbSuccess, cbError);
    }

    customerEmailExists = (email, cbSuccess, cbError) => {
        this.get(`/emailexists/${email}`, cbSuccess, cbError);
    }

    getSubscriptions = (cbSuccess, cbError) => {
        this.get(`/customers/me/subscriptions`, cbSuccess, cbError);
    }

    updateCustomer = (body, cbSuccess, cbError) => {
        this.put('/customers', body, cbSuccess, cbError);
    }

}
