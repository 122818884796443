import React from 'react';

import { IbanElement } from '@stripe/react-stripe-js';
import Accordion from '../../accordion';
import Sepa from "../../../assets/sepa-lastschrift.png";
const SepaPayment = ({ open, setOpen, show, setValid }) => {

    const ELEMENT_OPTIONS = {
        supportedCountries: ["SEPA"],
        placeholderCountry: "AT",
        style: {
            base: {
                color: "#32325d",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
                ":-webkit-autofill": {
                    color: "#32325d",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
                ":-webkit-autofill": {
                    color: "#fa755a",
                },
            },
        },
    };


    const images = <img alt="Sepa Logo" src={Sepa} style={{ height: 28 }} />;

    return <Accordion
        open={open}
        show={show}
        id={"sepa"}
        setOpen={setOpen}
        title={"Sepa"}
        rightContent={images}
    >
        <div className='content'>
            <IbanElement onChange={(el) => setValid(el.complete)} onReady={() => setValid(false)} options={ELEMENT_OPTIONS} />


            <hr />
            <div className="mandate-acceptance">
                <p style={{ color: '#777', fontSize: 12, lineHeight: '15px', marginBottom: 0 }}>
                    Mit der Angabe Ihrer Zahlungsinformationen und der Bestätigung dieser
                    Zahlung, ermächtigen Sie (A) die afreshed GmbH und Stripe, unseren
                    Zahlungsdienstleister, dazu Anweisungen an Ihre Bank zu senden, um (B)
                    Ihr Konto gemäß diesen Anweisungen zu belasten. Als Teil Ihrer Rechte
                    haben Sie Anspruch auf eine Rückerstattung von Ihrer Bank gemäß den
                    Bedingungen und den Vereinbarung mit Ihrer Bank. Eine Erstattung muss
                    innerhalb von 8 Wochen ab dem Datum der Belastung Ihres Kontos geltend
                    gemacht werden. Ihre Rechte werden in einer Erklärung erläutert, die Sie
                    bei Ihrer Bank erhalten können. Sie erklären sich damit einverstanden,
                    über künftige Abbuchungen bis zu 2 Tage vor dem Eintreffen der Abbuchung
                    benachrichtigt zu werden.
                </p>
            </div>
        </div>
    </Accordion>

}

export default SepaPayment;