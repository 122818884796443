import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import { useForm, Controller as FormController } from "react-hook-form";
import CartUnit from '../../communicator/http/CartUnit';
import { useCookies } from 'react-cookie';
import Navigation from '../../elements/navigation';
import Footer from '../../elements/footer';
import wrlog from '../../helper/functions/wrlog';

const Error = () => {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        window.scrollTo({
            top: 0
        })

        wrlog("location", location)

    }, []);


    const renderErrorReason = () => {
        wrlog("location", location)

        let reason = "";
        if (location.state !== null && location.state.type !== undefined) {
            reason = location.state.type;
        }

        switch (reason) {
            case 'failed':
                return "Zahlung ist fehlgeschlagen."
            case 'canceled':
                return "Zahlung wurde von dir abgebrochen."
            case 'action_required':
                return "Zahlung ist fehlgeschlagen."
            default:
                return "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut."
        }
    }


    return <>
        <Container style={{ padding: 0 }}>
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{ paddingTop: 50, paddingBottom: 50, minHeight: "calc(100vh - 207px)" }}>
                    <Navigation currentStep={2} />
                    <h2 className='normal' style={{ marginBottom: 10 }}>Kauf fehlgeschlagen!</h2>

                    <h5 className='normal'>{renderErrorReason()}</h5>
                </Col>
            </Row>
        </Container>
        <Footer />
        <Loading visible={loading} />
    </>

}

export default Error;